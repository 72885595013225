import { Map, Metas, Newsletter, Toast } from "components";
import { PER_PAGE_HOME } from "data/constants";
import { filterProductCatalogueData } from "helpers";
import Social from "layout/parts/footer/Social";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Logo } from "public/svg";
import React from "react";
import { getWoocommerceQuery } from "utils";
const geshenkImg = "/images/geshenk.jpg";

export default function Home() {
  const { t } = useTranslation();

  return (
    <div id="page" className="preLaunch">
      <Metas seo={{ title: t("Unser neuer KOH-I-NOOR onlineshop! ") }} />
      <main>
        <br />
        <br />
        <div className="logo">
          <Logo />
        </div>
        <br />
        <section className="preSection">
          <div style={{ textAlign: "center" }}>
            <h1>Riesiger Mal- und Zeichenspaß – nicht verpassen!</h1>
          </div>
          <h2 className="was">
            Demnächst verfügbar! Was?
            <br /> Unser neuer KOH-I-NOOR onlineshop!
          </h2>
          <br />
          <p>
            Melden Sie sich jetzt an: Die ersten 100 Kunden erhalten ein besonderes Geschenk für ihren ersten
            Einkauf – und um Ihr Geschenk zu erhalten, brauchen Sie keinen Mindestbestellwert zu erreichen.
          </p>
          <h2 className="when">Am 15. 6. 2023 geht es los!</h2>
          <br />
          <h2>Ihr Geschenk – einer unserer Bestseller:</h2>
          <br />
          <img src={geshenkImg} alt="Geshenk" height={250} className="cover" />
          <br />
          <br />
          <br />

          <h2>
            <strong>5 gute Gründe </strong>, sich bei KOH-I-NOOR Deutschland anzumelden:
          </h2>
          <br />
          <ol>
            <li>Sie sind sofort mit dabei, wenn unser neuer Onlineshop seine Tore öffnet. </li>
            <li>
              Erhalten Sie ein besonderes Geschenk, wenn Sie als eine der ersten 100 Personen bestellen – ganz
              ohne Mindestbestellwert.
            </li>
            <li>Sie bekommen Zugang zu VIP- und Treue-Rabatten. </li>
            <li>
              Sie werden immer zuerst informiert, wenn es aufregende Neuigkeiten zu unseren Kollektionen gibt.
            </li>
            <li>Der vielleicht wichtigste Grund: Sie bringen mehr Farbe in Ihr Leben! 🙂</li>
          </ol>
          <br />
          <h2 className="primary">Wir freuen uns darauf, Sie bald im Shop begrüßen zu dürfen!</h2>
          <br />
          <br />
          <iframe
            width="100%"
            height="305"
            src="https://941ae223.sibforms.com/serve/MUIEAGpBeqybOjJcJl4D9SyvhI-YKmxxTFRWy2Wy7L5QSEw6XVuurYayjLjSrxr19mV6CGOvdlwhBVRcDqRUvO2qnmXSjwBNIdJjp9u1oE4LEw2HzlOQmk4TzudRR3d5xq9pg1kFzNGyYaEfDJXY4Xbc7rRFthMenovABIrLwn_ys0t2zU3rFinmplLnoxN4kdA4Ejb-w1Z6IwSQ"
            frameborder="0"
            scrolling="auto"
            allowfullscreen
            style={{ display: "block", marginLeft: "auto", marginRight: "auto", maxWidth: "100%" }}
          ></iframe>
          {/* <Newsletter /> */}
          <br />
          <br />
          <p>
            Sie möchten nicht warten? Dann halten Sie schon jetzt Ausschau nach unseren Produkten – erhältlich
            in allen gut ausgestatteten Schreibwa4enläden. Auf der folgenden Karte finden Sie das
            nächstgelegene Geschäft:
          </p>
          <br />
        </section>
        <Map />
        <section className="preSection">
          <Social />
        </section>
        <Toast />
      </main>
    </div>
  );
}

export const getStaticProps = async ({ locale }) => {
  const { data } = await getWoocommerceQuery("/product", {
    page: 1,
    per_page: PER_PAGE_HOME,
    stock_status: "instock",
    orderby: "popularity",
    // order: "desc",
  });

  let catalogue = [];
  if (data) catalogue = filterProductCatalogueData(data);

  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
      catalogue,
    },
  };
};
